<template>
  <div class="my_suggest">
    <div class="warpper">
      <h2 class="title">意见建议</h2>
      <div class="log-record">
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          class="textarea"
          placeholder="请输入"
          maxlength="500"
        ></textarea>
        <div class="upload flex-align">
          <img src="@/assets/images/my/upload.png" alt="" />
          <span>上传图片</span>
        </div>
        <button class="save">提交</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  mounted() {}
};
</script>

<style lang="less">
.my_suggest {
  align-self: flex-start;
  border-radius: 10px;
  // box-shadow: 0px 5px 23px 0px rgba(22, 115, 255, 0.1);
  max-width: 819px;
  width: 819px;

  background: #ffffff;

  .warpper {
    padding-right: 9px;
  }

  .title {
    border-bottom: 1px solid #f9f9f9;
    padding-left: 35px;
    line-height: 69px;

    font-size: 24px;
  }

  .log-record {
    padding: 36px 26px 110px 36px;

    .textarea {
      resize: none;
      overflow-y: auto;
      margin-bottom: 18px;
      border: none;
      border-radius: 10px;
      padding: 17px;
      width: 95%;
      max-height: 161px;
      font-size: 16px;
      background-color: #ecebeb;
    }

    .upload {
      span {
        margin-left: 15px;
        font-size: 16px;
        color: #888;
      }
    }

    .save {
      display: block;

      margin-left: auto;
      box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
      border-radius: 5px;
      padding: 0 62px;
      line-height: 42px;

      font-size: 18px;
      color: #ffffff;
      background: linear-gradient(90deg, #2272ec, #3a7bf6);
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1250px) {
  .my_suggest {
    max-width: 660px;
    width: 660px;
  }
}
</style>
